// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-#{$color} {
        &.bg-soft{
            background-color: rgba(($value), 0.25) !important;

        }
        &.bg-soft1{
            background-color: rgba(#090b53, 1) !important;

        }
    }
}
